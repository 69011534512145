.central {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.board {
  width: 40%;
  height: 55%;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(9, 1fr);
  place-items: center;

  border-style: solid;
  border-width: 4px;
}

@media (min-width: 481px) and (max-width: 800px) {
  .board {
    height: 50%;
    width: 60%;
  }
}

@media (max-width: 480px) {
  .board {
    height: 40%;
    width: 80%;
  }
}

.cell {
  width: 100%;
  height: 100%;

  border-style: solid;
  border-width: 0.5px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
}

.cell-bottom-border {
  border-bottom-width: 4px;
}

.cell-right-border {
  border-right-width: 4px;
}

.number {
  width: 100%;
  height: 90%;

  border: none;

  font-weight: bold;
  text-align: center;
}

.number:focus {
  outline: none;
}

.solveButton {
  margin-top: 5vh;
}